import type { FC } from 'react';

import useLoadUserHoroscopesV2 from './use-load-user-horoscopes-v2';
import useLoadTodaysFeatures from './use-load-todays-features';
import useTrackAstroCalendarTodos from './use-track-astro-calendar-todos';
import useUpdateAppContent from './use-update-app-content';
import useHoroscopeSubscriptionTimeout from './use-horoscope-subscription-timeout';
import useLoadAdvisorHoroscopes from './use-load-advisor-horoscopes';

const StaticHooksRunner: FC = () => {
  useLoadUserHoroscopesV2();
  useLoadAdvisorHoroscopes();
  useLoadTodaysFeatures();
  useTrackAstroCalendarTodos();
  useUpdateAppContent();
  useHoroscopeSubscriptionTimeout();

  return null;
};

export default StaticHooksRunner;
