import NetInfo from '@react-native-community/netinfo';
import queryString from 'query-string';
import { AppStateStatus, Platform } from 'react-native';
import { getInitialShortcut } from 'react-native-siri-shortcut';
import { createAction } from 'redux-actions';
import PushNotification from 'react-native-push-notification';
import DeviceProps from '@magnus/react-native-device-props';

import Localization from 'localization';
import Analytics from 'analytics';
import * as APP from 'constants/app';
import { ADJUST_OFFER_QUERY_PARAM } from 'constants/general';
import * as ROUTES from 'constants/routes';
import { WIDGETS_DEEP_LINKS } from 'constants/native-widgets';
import { AppDispatch, AppGetState, AppThunk } from 'store';
import { initAuth, processWebUUID } from 'store/auth/actions';
import { initBestMatches } from 'store/best-matches/actions';
import { initBilling, verifyMobilePurchases } from 'store/billing/actions';
import { initBiorhythms } from 'store/biorhythms/action';
import { initCalendars } from 'store/calendars/actions';
import { initDatingCalendar, initDatingWidgets } from 'store/dating-calendar/actions';
import { initGuides } from 'store/guides/actions';
import { initAllSigns, loadZodiacForUser } from 'store/horoscope/actions';
import { initLunarPredictions, initLunarWidget } from 'store/lunar-calendar/actions';
import { initNatalCharts, initNatalChartsContent } from 'store/natal-charts/actions';
import { navigate, onNotificationOpen, whereToNavigate } from 'store/navigation/actions';
import { stepNavigate, setOnboardingCompleted } from 'store/onboarding/actions';
import { initRemoteConfig } from 'store/remote-config/actions';
import { handleNsUserActivityType, checkActiveShortcuts } from 'store/siri-shorcuts/actions';
import { initStories } from 'store/stories/actions';
import { generateTransits, initTransitsContent } from 'store/transits/actions';
import { showBackgroundPlacement } from 'store/unlock-content/actions';
import { initWidgets } from 'store/widgets/actions';
import { initPWAListeners } from 'store/pwa/actions';
import {
  initNativeWidgets,
  handleHoroscopeNativeWidgetPress,
  checkActiveNativeWidgets,
  setHoroscopeBannerMoved,
  checkAndNavigateIfNativeWidgetPressed,
} from 'store/native-widgets/actions';
import { isStandalone } from 'utils/pwa';
import { capitalizeFirstLetter } from 'utils/strings';
import { PUSH_BEHAVIOR } from 'constants/messaging';
import * as MODALS from 'constants/modals';
import { initPromoOffers } from 'modules/promo-offers/store/actions';
import { handleExternalTrigger, initAstrologist, setPromocode } from 'store/astrologers/core/actions';
import { checkHoroscopeSubscriptionStatuses } from 'store/astrologers/horoscope/actions';
import { getWebData } from 'store/palm-reading-daily/actions';
import { initTarot } from 'store/tarot/actions';
import { initBirthChartsCompatibility } from 'store/birth-charts-compatibility/actions';
import { setUserParams, updateProfileLang } from 'store/profile/actions';
import { initTrustPilotPromoCode } from 'store/rate-us/actions';
import { setTimePaused } from 'store/astrologers/time/actions';

import Messaging from '../../messaging';
import SiriShortcuts from '../../siri-shortcuts';
import { initCompatibilityReports } from '../compatibility-reports/actions';
import { initFollowersData } from '../guides/actions';
import { showModal } from '../modals/actions';
import { initMoonPhase } from '../moon-phases/actions';
import { isNotificationAvailableToShow } from '../notification/actions';
import { initRetrograde } from '../retrograde-planets/actions';
import { checkResetVisited } from '../widgets/actions';

import {
  SET_APP_STATUS,
  SET_IS_FULL_PROFILE,
  SET_IS_LOADED,
  SET_INTERNET_CONNECTION,
  SET_WAS_ON_CONTENT_PAGES,
  SET_OVERLAY_LOADER_ACTIVITY,
  SET_ROLL_UP_PUSH_ON_DASHBOARD_SHOWN,
  SET_WEB_MODE,
  SET_IS_ANALYTICS_INITED,
  SET_INITIAL_SYSTEM_LOCALE,
  SET_INSTALL_VERSION,
  SET_INITIAL_URL,
} from './types';

export const setInternetConnection = createAction(SET_INTERNET_CONNECTION);
export const setAppStatus = createAction(SET_APP_STATUS);
export const setIsLoaded = createAction(SET_IS_LOADED);
export const setIsAnalyticsInited = createAction(SET_IS_ANALYTICS_INITED);
export const toggleOverlayLoaderActivity = createAction(SET_OVERLAY_LOADER_ACTIVITY);
export const setWasOnContentPages = createAction(SET_WAS_ON_CONTENT_PAGES);
export const setIsFullProfile = createAction(SET_IS_FULL_PROFILE);
export const setRollUpPushOnDashboardShown = createAction(SET_ROLL_UP_PUSH_ON_DASHBOARD_SHOWN);
export const setInitialSystemLocale = createAction(SET_INITIAL_SYSTEM_LOCALE);
export const setWebMode = createAction(SET_WEB_MODE);
export const setInstallVersion = createAction(SET_INSTALL_VERSION);
export const setInitialUrl = createAction(SET_INITIAL_URL);

const requiredFields = ['name', 'date', 'gender', 'relationship'];
type RequiredFields = 'name' | 'date' | 'gender' | 'relationship';

export const handleDeepLink = (url: string, isDeferred: boolean = false) => {
  return async (dispatch: AppDispatch) => {
    const parsedUrlQuery = queryString.parseUrl(url)?.query;

    let source = parsedUrlQuery && parsedUrlQuery?.source;
    const invited = parsedUrlQuery && parsedUrlQuery?.invite;
    const webUUID = parsedUrlQuery && parsedUrlQuery?.uuid;
    const promocode = parsedUrlQuery && parsedUrlQuery?.promocode;
    const utmSource = parsedUrlQuery && parsedUrlQuery.utm_source;
    const ua_creative_topic = parsedUrlQuery && parsedUrlQuery.ua_creative_topic;

    if (url === WIDGETS_DEEP_LINKS.HOROSCOPE) {
      dispatch(handleHoroscopeNativeWidgetPress());
    }

    if (utmSource) {
      Analytics.setUserProperty('utm_source', String(utmSource));
    }

    if (ua_creative_topic) {
      Analytics.setUserProperty('ua_creative_topic', String(ua_creative_topic));
    }

    if (promocode) {
      dispatch(setPromocode(promocode));
    }

    if (webUUID) {
      source = Platform.OS === 'web' && !source ? 'flow' : source;
      dispatch(processWebUUID(webUUID as string, isDeferred, source as string));
    }

    if (invited === ADJUST_OFFER_QUERY_PARAM) {
      Analytics.trackEvent('offer', 'user_defined');
      dispatch(showModal(MODALS.OFFER_WELCOME));
    }
  };
};

const trackUrlParams = () => {
  if (Platform.OS !== 'web') {
    return false;
  }

  try {
    const referrer = document.referrer;
    const pathname = location.pathname;
    const search = location.search.substring(1);
    const params = search ? JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/[=]/g, '":"') + '"}') : {};
    Analytics.trackEvent('web_session', 'url_params', { ...params, pathname, referrer });
  } catch (e) {
    console.log('> Track Url Params Error', e);
  }
};

export function initApp() {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const isInternetConnected = await dispatch(checkInternetConnection());

    if (!isInternetConnected) {
      throw new Error('is not Internet');
    }

    await Analytics.init();
    dispatch(setIsAnalyticsInited());

    Analytics.trackEvent('init_app', 'start');

    trackUrlParams();

    if (!isStandalone() && Platform.OS !== 'macos' && Platform.OS !== 'windows') {
      Analytics.setUserProperty(`installVersion${capitalizeFirstLetter<typeof Platform.OS>(Platform.OS)}`, dispatch(getInstallVersion()));
    }
    Analytics.setUserProperty('isStandalone', isStandalone());

    const remoteConfig = await dispatch(initRemoteConfig());

    const promoOffersEnabled = !!remoteConfig?.promoOffers?.enabled;
    Analytics.setUserProperty('promoOffersEnabled', promoOffersEnabled);

    const { isOnboarding } = remoteConfig;

    await Promise.all([dispatch(initAuth()), dispatch(initWidgets())]);

    await dispatch(initBilling());

    dispatch(initPromoOffers());
    dispatch(initPWAListeners());
    dispatch(initTrustPilotPromoCode());
    dispatch(mergeLocalizations());

    const sessionNumber = Analytics.getSessionNumber() ?? 0;

    if (sessionNumber > 0) {
      dispatch(checkAndSetFullProfile()); // TODO for backward compatibility; possible to delete after few releases after 3.5.x
      Messaging.removeAllDeliveredNotifications();
    }

    let wasNavigation = false;
    let navigateToPromoSubPage = false;

    Messaging.onOpenNotificationHandler((notification: any, isInitialOpen = false) => {
      const notificationData = dispatch(onNotificationOpen(notification, isInitialOpen));
      wasNavigation = !!notificationData?.navigatedByNotification;
      navigateToPromoSubPage = !!notificationData?.isPromoInitialNotification;
    });

    await Promise.all([dispatch(initAllSigns()), dispatch(loadZodiacForUser()), dispatch(initAstrologist())]);

    Analytics.trackEvent('init_app', 'navigation_start');

    const {
      onboarding: { isOnboardingCompleted },
      nativeWidgets: { isHoroscopeBannerPressed, isHoroscopeBannerMoved },
    } = getState();

    if (isOnboarding && !isOnboardingCompleted) {
      Analytics.trackEvent('init_app', 'navigation_onboarding');
      dispatch(stepNavigate(true));
    } else {
      if (!isOnboardingCompleted) {
        Analytics.trackEvent('init_app', 'navigation_onboarding_set_completed');
        dispatch(setOnboardingCompleted());
      }

      if (!wasNavigation) {
        Analytics.trackEvent('init_app', 'navigation_to_main');
        dispatch(whereToNavigate());
      }
    }

    if (navigateToPromoSubPage) {
      navigate(ROUTES.SUBSCRIPTION_PROMO_OFFER);
    }

    Analytics.trackEvent('init_app', 'navigation_finish');

    dispatch(defineInitialSystemLocale());
    dispatch(initCalendars());
    dispatch(initBiorhythms());
    dispatch(initGuides());
    dispatch(initBestMatches());
    dispatch(initNatalCharts());
    dispatch(initNatalChartsContent());
    dispatch(generateTransits());
    dispatch(initTransitsContent());
    dispatch(initDatingCalendar());
    dispatch(initDatingWidgets());
    dispatch(initLunarWidget());
    dispatch(initLunarPredictions());
    dispatch(initStories());
    dispatch(initNativeWidgets());
    dispatch(setIsLoaded());
    dispatch(getWebData());
    dispatch(initTarot());
    dispatch(initBirthChartsCompatibility());
    dispatch(checkHoroscopeSubscriptionStatuses());

    const userParams = getState().profile?.profileData?.userParams;
    if (Platform.OS !== 'web' && !userParams?.app_installed) {
      dispatch(setUserParams({ app_installed: true }));
    }

    if (isHoroscopeBannerPressed && !isHoroscopeBannerMoved) {
      dispatch(setHoroscopeBannerMoved());
    }

    if (SiriShortcuts.supported) {
      dispatch(checkActiveShortcuts());
      const initialShortcut = await getInitialShortcut();

      if (initialShortcut) {
        dispatch(handleNsUserActivityType(initialShortcut));
      }
    }
  };
}

export function onAppStateChanged(nextAppState: AppStateStatus) {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const {
      app: { wasOnContentPages, appStatus, isRollUpPushOnDashboardShown },
      billing: { purchased },
      navigation: { currentRoute },
      auth: { accessToken },
      remoteConfig,
    } = state;

    const afterUse = remoteConfig?.remoteConfigParams?.pushConfig?.afterUse || PUSH_BEHAVIOR.NONE;

    // to foreground
    if (appStatus === APP.BACKGROUND && nextAppState !== APP.BACKGROUND) {
      await dispatch(verifyMobilePurchases());

      dispatch(showBackgroundPlacement());
      dispatch(checkAndNavigateIfNativeWidgetPressed());

      if (currentRoute === ROUTES.ADVISORS_CHAT) {
        dispatch(setTimePaused(false));
      }

      if (accessToken) {
        dispatch(checkResetVisited());
        dispatch(initMoonPhase());
        dispatch(initRetrograde());
        dispatch(initCalendars());
        dispatch(initBestMatches());
        dispatch(initCompatibilityReports());
        dispatch(initFollowersData());
        dispatch(initLunarWidget());
        dispatch(initLunarPredictions());
        dispatch(initDatingCalendar());
        dispatch(initDatingWidgets());
        dispatch(initStories());
        dispatch(checkActiveNativeWidgets());
        dispatch(loadZodiacForUser());
        dispatch(handleExternalTrigger());
      }
    }

    // to background
    if (appStatus !== APP.BACKGROUND && nextAppState === APP.BACKGROUND) {
      if (dispatch(isNotificationAvailableToShow()) && ROUTES.FULL_ONBOARDING_ROUTES.includes(currentRoute)) {
        Messaging.scheduleRollUpProfileNotification();
      }

      if (currentRoute === ROUTES.ADVISORS_CHAT) {
        dispatch(setTimePaused(true));
      }

      if (
        wasOnContentPages &&
        !ROUTES.BACKGROUND_EXCLUDE_ROUTES.includes(currentRoute) &&
        (afterUse === PUSH_BEHAVIOR.ALL || (purchased && afterUse === PUSH_BEHAVIOR.PAID) || (!purchased && afterUse === PUSH_BEHAVIOR.FREE))
      ) {
        Messaging.scheduleRollUpNotificationAfterUse();
      }

      if (!wasOnContentPages && currentRoute === ROUTES.DAILY && dispatch(isNotificationAvailableToShow()) && !isRollUpPushOnDashboardShown) {
        dispatch(setRollUpPushOnDashboardShown());
        Messaging.scheduleRollUpDashboardNotification();
      }
    }

    if (Platform.OS !== 'web') {
      PushNotification.getApplicationIconBadgeNumber((badgeCount: number) => {
        if (badgeCount > 0) {
          PushNotification.setApplicationIconBadgeNumber(0);
        }
      });
    }

    // TODO fix for https://wowmaking.atlassian.net/browse/AS-5945
    // if (Platform.OS === 'web') {
    //   document?.activeElement?.blur?.();
    // }

    dispatch(setAppStatus(nextAppState));
  };
}

export function onMountApp() {
  return (dispatch: AppDispatch) => {
    dispatch(checkResetVisited());
    dispatch(initMoonPhase());
    dispatch(initRetrograde());
    dispatch(initCompatibilityReports());
    dispatch(initFollowersData());
  };
}

export function checkInternetConnection() {
  return async (dispatch: AppDispatch) => {
    const state = await NetInfo.fetch();
    dispatch(setInternetConnection(state.isConnected));
    return state.isConnected;
  };
}

export function checkAndSetFullProfile() {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      app: { isFullProfile },
      profile: { profileData },
    } = getState();

    if (!isFullProfile && !requiredFields.some((el: string) => !profileData[el as RequiredFields])) {
      dispatch(setIsFullProfile());
    }
  };
}

export function defineInitialSystemLocale() {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      profile: { profileData },
      app: { initialSystemLocale },
    } = getState();

    if (!initialSystemLocale) {
      const currentLocale = Localization.getLngCode();
      const systemLocale = Localization.getSystemLngCode();
      try {
        if (systemLocale !== profileData?.lang || systemLocale !== currentLocale) {
          await dispatch(updateProfileLang(systemLocale));
        }
      } catch (e) {
        console.log('> Update profile error', e);
      }
      dispatch(setInitialSystemLocale(systemLocale));
    }
  };
}

export const generateAppLink = (appLink: string, params?: { [key: string]: string | number | boolean | undefined }) => {
  return async (_dispatch: AppDispatch, getState: AppGetState) => {
    const {
      auth: { webUUID },
    } = getState();

    const utmSource = await Analytics.getUserProperty('utm_source');
    const source = await Analytics.getUserProperty('web_source');

    const link = queryString.stringifyUrl({
      url: appLink,
      query: {
        uuid: webUUID,
        source,
        utmSource,
        ...params,
      },
    });

    return link;
  };
};

export function getInstallVersion(): AppThunk<string> {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      app: { installVersion },
    } = getState();

    if (installVersion) {
      return installVersion;
    } else {
      const appVersion = DeviceProps.getAppVersion();
      dispatch(setInstallVersion(appVersion));
      return appVersion;
    }
  };
}

export const mergeLocalizations = () => {
  return (_dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: { remoteConfigParams },
    } = getState();

    Localization.addTranslations(remoteConfigParams?.localizations || {});
  };
};
