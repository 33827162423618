import { createAction } from 'redux-actions';
import _ from 'lodash';
import dayjs from 'dayjs';

import { AppDispatch, AppGetState, AppThunk } from 'store';
import { navigate } from 'store/navigation/actions';
import { ADVISORS_CATEGORIES } from 'screens/advisors/screens/catalog/types';
import * as ROUTES from 'constants/routes';
import { ASTROLOGERS_OPEN_PLACE, TOP_TAB_IDS } from 'screens/advisors/constants';

import { requestAstrologistReviews, setAstrologistAction } from '../core/actions';
import { getActiveAdvisors, getAstrologistDataById } from '../selectors';

import { TYPES } from './types';

const RANDOM_NUMBER_OF_ADVISORS = 7;

export const setCategories = createAction(TYPES.SET_CATEGORIES);
export const setPreviousDateForThreeDays = createAction(TYPES.SET_PREVIOUS_DATE_FOR_THREE_DAYS);
export const setPreviousDateForSevenDays = createAction(TYPES.SET_PREVIOUS_DATE_FOR_SEVEN_DAYS);

export const initAdvisorsCatalog = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();

    const {
      astrologers: {
        catalog: { previousDateForThreeDays, previousDateForSevenDays },
      },
    } = state;

    const advisors = getActiveAdvisors(state);

    const ids = advisors.map(item => item.astrologer_id);
    const getRandomAdvisors = () => _.sampleSize(ids, RANDOM_NUMBER_OF_ADVISORS);

    const generateItemsForThreeDays = !previousDateForThreeDays || dayjs(previousDateForThreeDays).add(3, 'day').isSame(dayjs(), 'days');
    const generateItemsForSevenDays = !previousDateForSevenDays || dayjs(previousDateForSevenDays).add(7, 'day').isSame(dayjs(), 'days');

    if (generateItemsForThreeDays) {
      dispatch(setCategories({ [ADVISORS_CATEGORIES.RECOMMENDED]: getRandomAdvisors(), [ADVISORS_CATEGORIES.LOVE]: getRandomAdvisors() }));
      dispatch(setPreviousDateForThreeDays(dayjs().format()));
    }

    if (generateItemsForSevenDays) {
      dispatch(setCategories({ [ADVISORS_CATEGORIES.ACCURATE]: getRandomAdvisors() }));
      dispatch(setPreviousDateForSevenDays(dayjs().format()));
    }
  };
};

export const openChatWithRandomAstrologist = (): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();

    const advisors = getActiveAdvisors(state);
    const astrologist = state.astrologers.core.astrologist;

    const randomAstrologist = _.sample(_.without(advisors, astrologist));
    dispatch(selectAnAdvisor(randomAstrologist?.astrologer_id || astrologist.astrologer_id));
  };
};

export const selectAnAdvisor = (id: number | string, destinationRoute?: TOP_TAB_IDS, place?: ASTROLOGERS_OPEN_PLACE): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const astrologist = getAstrologistDataById(getState(), id);
    if (astrologist) {
      dispatch(setAstrologistAction(astrologist));
      dispatch(requestAstrologistReviews(astrologist.astrologer_id));

      const originPlace = place || ASTROLOGERS_OPEN_PLACE.ADVISORS_CATALOG;
      navigate(ROUTES.ADVISOR_PAGE, { destinationRoute, place: originPlace });
    }
  };
};
