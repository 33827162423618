import { Astrologist } from 'api/astrology-chat/constants';

export enum TYPES {
  SET_PERMISSION_REQUESTED = 'NOTIFICATIONS/SET_PERMISSION_REQUESTED',
  SET_WEB_NOTIFICATION_REQUEST_OVERLAY_VISIBILITY = 'NOTIFICATIONS/SET_WEB_NOTIFICATION_REQUEST_OVERLAY_VISIBILITY',
  SET_NOTIFICATION_TIME = 'NOTIFICATIONS/SET_NOTIFICATION_TIME',
  SET_FCM_TOKEN_HASH = 'NOTIFICATIONS/SET_FCM_TOKEN_HASH',
}

export interface State {
  isPermissionRequested: boolean;
  isWebNotificationRequestOverlayVisible: boolean;
  fcmTokenHash: string;
  notificationTime: string;
}

interface SetPermissionRequested {
  type: typeof TYPES.SET_PERMISSION_REQUESTED;
}

interface SetWebNotificationRequestOverlayVisibility {
  type: typeof TYPES.SET_WEB_NOTIFICATION_REQUEST_OVERLAY_VISIBILITY;
  payload: boolean;
}

interface SetNotificationTime {
  type: typeof TYPES.SET_NOTIFICATION_TIME;
  payload: string;
}

interface SetFcmToken {
  type: typeof TYPES.SET_FCM_TOKEN_HASH;
  payload: string;
}

export type ActionTypes = SetNotificationTime | SetPermissionRequested | SetFcmToken | SetWebNotificationRequestOverlayVisibility;

export interface NotificationType {
  action: 'redirect-from-notificationclick';
  astrologer_id: Astrologist['astrologer_id'];
  isInitial: boolean;
  type: 'horoscope';
  body: string;
}
