import { useEffect, useRef } from 'react';

import { useAppDispatch, useAppSelector } from 'store';
import { ADVISOR_HOROSCOPE_SUB_STATUSES } from 'store/astrologers/horoscope/types';
import { setHoroscopeSubscriptionActiveStatus, setHoroscopeTimerEnabled } from 'store/astrologers/horoscope/actions';
import { getRemainingTimerDuration, getRemainingTimerDurationForNextDay } from 'utils/timer';

const useHoroscopeSubscriptionTimeout = () => {
  const dispatch = useAppDispatch();
  const appStatus = useAppSelector(state => state.app.appStatus);
  const advisorHoroscopeTimerDuration = useAppSelector(state => state.remoteConfig.remoteConfigParams.advisorHoroscopeTimerDuration);
  const horoscopeTimerEnabled = useAppSelector(state => state.astrologers.horoscope.horoscopeTimerEnabled);
  const horoscopeSubscriptions = useAppSelector(state => state.astrologers.horoscope.subscriptions);
  const isPendingSubscription = horoscopeSubscriptions.find(subscription => subscription.status === ADVISOR_HOROSCOPE_SUB_STATUSES.PENDING);

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const resetTimeout = () => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  useEffect(() => {
    if (horoscopeTimerEnabled && appStatus === 'active') {
      if (isPendingSubscription) {
        const prevSubscriptions = horoscopeSubscriptions.filter(subscription => subscription.id !== isPendingSubscription.id);
        let duration: number = 0;
        if (prevSubscriptions.length) {
          duration = getRemainingTimerDurationForNextDay();
        } else {
          duration = getRemainingTimerDuration(isPendingSubscription.lastUpdatedAt, advisorHoroscopeTimerDuration);
        }

        timeoutRef.current = setTimeout(() => {
          dispatch(setHoroscopeSubscriptionActiveStatus(isPendingSubscription.id));
          dispatch(setHoroscopeTimerEnabled(false));

          resetTimeout();
        }, duration * 1000);
      }
    } else {
      resetTimeout();
    }

    return () => {
      resetTimeout();
    };
  }, [advisorHoroscopeTimerDuration, appStatus, dispatch, horoscopeSubscriptions, horoscopeTimerEnabled, isPendingSubscription]);
};

export default useHoroscopeSubscriptionTimeout;
