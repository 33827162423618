import { Astrologist } from 'api/astrology-chat/constants';
import { AdvisorHoroscopePeriods } from 'screens/advisors/constants';
import { AdvisorHoroscopeContent } from 'interfaces/horoscope';

import { ResetUserData } from '../types';

export enum ADVISOR_HOROSCOPE_SUB_STATUSES {
  ACTIVE = 'active',
  PENDING = 'pending',
}

export type HoroscopeIds = {
  [key in AdvisorHoroscopePeriods]: number | null;
};

export interface AdvisorHoroscopeSubData {
  id: Astrologist['astrologer_id'];
  status: ADVISOR_HOROSCOPE_SUB_STATUSES;
  lastUpdatedAt: string;
  isViewed: boolean;
  horoscopeIds: HoroscopeIds;
}

export type HoroscopeContent = {
  [key in AdvisorHoroscopePeriods]: AdvisorHoroscopeContent | null;
};

export interface State {
  subscriptions: AdvisorHoroscopeSubData[];
  dataIsUpdated: boolean;
  horoscopeTimerEnabled: boolean;
  horoscopeContent: HoroscopeContent;
  needResetCache: boolean;
}

export enum TYPES {
  SET_SUBSCRIPTIONS_DATA = 'ASTROLOGERS/HOROSCOPE/SET_SUBSCRIPTIONS_DATA',
  SET_HOROSCOPE_TIMER_ENABLED = 'ASTROLOGERS/HOROSCOPE/SET_HOROSCOPE_TIMER_ENABLED',
  SET_DATA_IS_UPDATED = 'ASTROLOGERS/HOROSCOPE/SET_DATA_IS_UPDATED',
  SET_HOROSCOPE_CONTENT = 'ASTROLOGERS/HOROSCOPE/SET_HOROSCOPE_CONTENT',
  SET_NEED_RESET_CACHE = 'ASTROLOGERS/HOROSCOPE/SET_NEED_RESET_CACHE',
}

interface SetAdvisorHoroscopeSubData {
  type: typeof TYPES.SET_SUBSCRIPTIONS_DATA;
  payload: AdvisorHoroscopeSubData[];
}
interface SetHoroscopeTimerEnabled {
  type: typeof TYPES.SET_HOROSCOPE_TIMER_ENABLED;
  payload: boolean;
}
interface SetDataIsUpdated {
  type: typeof TYPES.SET_DATA_IS_UPDATED;
}

interface SetHoroscopeContent {
  type: typeof TYPES.SET_HOROSCOPE_CONTENT;
  payload: { period: AdvisorHoroscopePeriods; content: AdvisorHoroscopeContent | null };
}
interface SetNeedResetCache {
  type: typeof TYPES.SET_NEED_RESET_CACHE;
  payload: boolean;
}

export type ActionType =
  | SetAdvisorHoroscopeSubData
  | SetHoroscopeTimerEnabled
  | SetDataIsUpdated
  | SetHoroscopeContent
  | SetNeedResetCache
  | ResetUserData;
