import { PROFILE_TYPES } from 'store/profile/types';

import { TYPES, ActionType, State } from './types';
import { defaultHoroscopeContent } from './constants';

const initialState: State = {
  subscriptions: [],
  dataIsUpdated: false,
  horoscopeTimerEnabled: false,
  horoscopeContent: defaultHoroscopeContent,
  needResetCache: false,
};

export default function (state = initialState, action: ActionType): State {
  switch (action.type) {
    case TYPES.SET_SUBSCRIPTIONS_DATA:
      return {
        ...state,
        subscriptions: action.payload,
      };

    case TYPES.SET_HOROSCOPE_TIMER_ENABLED:
      return {
        ...state,
        horoscopeTimerEnabled: action.payload,
      };

    case TYPES.SET_DATA_IS_UPDATED:
      return {
        ...state,
        dataIsUpdated: true,
      };

    case TYPES.SET_NEED_RESET_CACHE:
      return {
        ...state,
        needResetCache: action.payload,
      };

    case TYPES.SET_HOROSCOPE_CONTENT:
      return {
        ...state,
        horoscopeContent: {
          ...state.horoscopeContent,
          [action.payload.period]: action.payload.content,
        },
      };

    case PROFILE_TYPES.RESET_USER_DATA:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
