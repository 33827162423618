import dayjs from 'dayjs';
import { floor, toString } from 'lodash';

export const getRemainingTimerDuration = (timestamp: string, durationInSeconds: number): number => {
  const now = dayjs();
  const timerEnd = dayjs(timestamp).add(durationInSeconds, 'seconds');
  const timeLeft = dayjs.duration(timerEnd.diff(now));
  return floor(timeLeft.as('seconds'));
};

export const getRemainingTimerDurationForNextDay = (): number => {
  const now = dayjs();
  const timerEnd = dayjs().add(1, 'days').startOf('day');
  const timeLeft = dayjs.duration(timerEnd.diff(now));
  return floor(timeLeft.as('seconds'));
};

export const getTimerValues = (value: number) => {
  const hours = getTimerNumber(floor(value / 3600));
  const minutes = getTimerNumber(floor((value % 3600) / 60));
  const seconds = getTimerNumber(floor(value % 60));

  return {
    hours,
    minutes,
    seconds,
  };
};

export const getTimerNumber = (num: number): string => {
  if (num <= 0) {
    return '00';
  } else if (num < 10 && num > 0) {
    return `0${num}`;
  } else {
    return toString(num);
  }
};
