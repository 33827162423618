import { PERIODS_IDS } from 'constants/periods';

import { HoroscopeContent, HoroscopeIds } from './types';

export const defaultHoroscopeContent: HoroscopeContent = {
  [PERIODS_IDS.TODAY]: null,
  [PERIODS_IDS.WEEK]: null,
  [PERIODS_IDS.MONTH]: null,
  [PERIODS_IDS.YEAR]: null,
};

export const defaultHoroscopeIds: HoroscopeIds = {
  [PERIODS_IDS.TODAY]: null,
  [PERIODS_IDS.WEEK]: null,
  [PERIODS_IDS.MONTH]: null,
  [PERIODS_IDS.YEAR]: null,
};
