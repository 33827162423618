export enum NOTIFICATIONS {
  PWA_INSTRUCTIONS = 'PWA_INSTRUCTIONS',
  PERSONAL_REPORTS = 'PERSONAL_REPORTS',
  ASTROLOGER = 'ASTROLOGER',
  WHATS_APP = 'WHATS_APP',
  MESSENGERS = 'MESSENGERS',
  ADVISOR_HOROSCOPE = 'ADVISOR_HOROSCOPE',
}

export enum ADVISOR_NOTIFICATIONS {
  CONTEXT = 'context',
  TRANSIT = 'transit',
  RELATIONSHIP = 'relationship',
  GENERAL = 'general',
}
