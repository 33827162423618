import { Linking, Platform } from 'react-native';
import DeviceProps from '@magnus/react-native-device-props';
import { createAction } from 'redux-actions';

import Analytics from 'analytics';
import { AppDispatch, AppGetState } from 'store';
import { createOneClickPayment, getPaymentMethod, getWebSubs, getWebOneTimePurchases, getWebAllSubs } from 'api/purchases';
import { OneClickPaymentData, OneTimePaymentResponse, WebSubscription, WebPurchase } from 'api/purchases/interfaces';
import { SUB_CANCEL_LINK, WEB_SUB_CANCEL_LINK } from 'constants/general';
import { addTriggerNotification } from 'store/astrologers/notifications/actions';
import { WEB_SUBSCRIPTION_STATUS, WEB_SUBSCRIPTION_STATE } from 'constants/billing';
import { EXTERNAL_TRIGGER_NOTIFICATIONS } from 'components/trigger-notification/components';

import { BILLING_TYPES } from './types';

const setWebPaymentMethod = createAction(BILLING_TYPES.SET_WEB_PAYMENT_METHOD);
const setWebTransactions = createAction(BILLING_TYPES.SET_WEB_TRANSACTIONS);
const setWebOneTimePurchases = createAction(BILLING_TYPES.SET_WEB_ONE_TIME_PURCHASES);
const setWebAllSubscriptions = createAction(BILLING_TYPES.SET_WEB_ALL_SUBSCRIPTIONS);
const setRedemptionSubscription = createAction(BILLING_TYPES.SET_WEB_REDEMPTION_SUBSCRIPTION);

export const trackEvent = (event: string, params?: any) => Analytics.trackEvent('billing', event, params);

export const cancelSubscription = () => {
  return async (_dispatch: AppDispatch, getState: AppGetState) => {
    const {
      auth: { webUUID },
      billing: { webTransactions },
    } = getState();

    const webSubEmail = webTransactions?.find(sub => sub?.customer_email)?.customer_email || '';

    const idfm = webUUID ? webUUID : await DeviceProps.getIDFM();
    const link = webSubEmail ? `${WEB_SUB_CANCEL_LINK}${encodeURIComponent(webSubEmail)}&idfm=${idfm}&platform=${Platform.OS}` : SUB_CANCEL_LINK;
    Linking.openURL(link || '');
  };
};

export const fetchPaymentMethod = () => {
  return async (dispatch: AppDispatch) => {
    const paymentMethod = await getPaymentMethod();
    return dispatch(setWebPaymentMethod(paymentMethod));
  };
};

export const payByOneClick = ({
  data,
  onSuccess,
  onError,
}: {
  data: OneClickPaymentData;
  onSuccess?: (res: OneTimePaymentResponse['one_time_payment']) => void;
  onError?: (error: any) => void;
}) => {
  return async (_dispatch: AppDispatch, _getState: AppGetState) => {
    try {
      const response = await createOneClickPayment(data);

      if (typeof onSuccess === 'function') {
        onSuccess(response.one_time_payment);
      }

      return response;
    } catch (error) {
      console.log('[ERROR PAY BY ONE CLICK]: ', error);
      if (typeof onError === 'function') {
        onError(error);
      }
    }
  };
};

export const verifyWebSubscriptions = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const allSubscriptions = await getWebAllSubs();
    const state = getState();

    const {
      remoteConfig: { remoteConfigParams },
      billing: { redemptionSubscription },
    } = state;

    const isPaymentFailedTriggerNotificationEnabled = remoteConfigParams?.paymentFailedTriggerNotificationEnabled;

    const subscriptions = allSubscriptions.map((purchase: WebPurchase) => {
      const purchaseState = purchase.state?.toLowerCase() as WEB_SUBSCRIPTION_STATE;
      let status = purchase.state?.toLowerCase() as WEB_SUBSCRIPTION_STATUS;

      if (
        [WEB_SUBSCRIPTION_STATE.CANCELED, WEB_SUBSCRIPTION_STATE.CANCELLED].includes(purchaseState) ||
        (purchaseState === WEB_SUBSCRIPTION_STATE.ACTIVE && purchase.canceled_at)
      ) {
        status = WEB_SUBSCRIPTION_STATUS.CANCELED;
      }

      if (purchase.expire_at) {
        const now = new Date();
        const expireDate = new Date(purchase.expire_at.replace(' ', 'T'));

        if (now > expireDate) {
          status = WEB_SUBSCRIPTION_STATUS.EXPIRED;
        }
      }

      if (purchaseState === WEB_SUBSCRIPTION_STATE.REDEMPTION) {
        status = WEB_SUBSCRIPTION_STATUS.REDEMPTION;
      }

      return {
        ...purchase,
        status,
      } as WebSubscription;
    });

    dispatch(setWebAllSubscriptions(subscriptions));

    const redemption = subscriptions.find(s => s.status === WEB_SUBSCRIPTION_STATUS.REDEMPTION);

    if (redemption) {
      Analytics.track('Subscription_Redemption_In');

      dispatch(setRedemptionSubscription(redemption));

      if (isPaymentFailedTriggerNotificationEnabled) {
        Analytics.trackEvent('TopNotification', 'Show', {
          type: EXTERNAL_TRIGGER_NOTIFICATIONS.PAYMENT_FAILED,
        });

        dispatch(addTriggerNotification({ type: EXTERNAL_TRIGGER_NOTIFICATIONS.PAYMENT_FAILED }));
      }
    } else if (redemptionSubscription) {
      Analytics.track('Subscription_Redemption_Out');

      dispatch(setRedemptionSubscription(null));
    }

    return subscriptions;
  };
};

export const fetchWebPurchases = () => {
  return async (dispatch: AppDispatch) => {
    const [transactions, oneTimePurchases] = await Promise.all([getWebSubs(), getWebOneTimePurchases()]);

    dispatch(setWebTransactions(transactions));
    dispatch(setWebOneTimePurchases(oneTimePurchases));
    dispatch(fetchPaymentMethod());
  };
};
