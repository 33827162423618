import React, { memo, useEffect, useState } from 'react';
import type { FC } from 'react';
import { Image, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { fs, paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';
import dayjs from 'dayjs';
import Animated, { FadeIn } from 'react-native-reanimated';

import * as COLORS from 'constants/colors';
import Header from 'components/header';
import { t } from 'localization';
import Icon from 'components/icon';
import Text from 'components/text';
import Analytics from 'analytics';
import { useAppDispatch, useAppSelector } from 'store';
import { navigate, replace } from 'store/navigation/actions';
import * as ROUTES from 'constants/routes';
import { getCardById, getTodaysCardId } from 'store/tarot/selectors';
import AstrologersPromo from 'components/banner/astrologers-promo';
import { ASTROLOGERS_PROMO_SIZES } from 'components/banner/astrologers-promo/constants';
import { ASTROLOGERS_PROMO_PLACES } from 'screens/advisors/constants';
import CachedImage from 'components/cached-image';
import * as SIZES from 'constants/sizes';
import { addActiveTodo } from 'store/astro-calendar/actions';
import { ASTRO_CALENDAR_NAVIGATE_TODO_ID, ASTRO_CALENDAR_TODO_TYPE } from 'screens/astro-calendar/constants';

import Background from '../components/background';
import BG from '../images/card-description-bg.png';
import { isTodayDate } from '../utils';

interface Props {
  route: {
    params: {
      cardId: number;
      date?: string;
      navigateFromCalendar?: boolean;
    };
  };
}

const CardOfTheDay: FC<Props> = ({ route }) => {
  const dispatch = useAppDispatch();

  const cardOfTheDayId = useAppSelector(getTodaysCardId);

  const navigateFromCalendar = route?.params?.navigateFromCalendar;
  const cardId = route?.params?.cardId ?? cardOfTheDayId;
  const date = route?.params?.date ?? dayjs().format('YYYY-MM-DD');

  const card = useAppSelector(state => getCardById(state, cardId));
  const isToday = isTodayDate(date);

  const [cancelScreenAnimation, setCancelScreenAnimation] = useState<boolean>(false);

  const handleCalendarPress = () => {
    Analytics.trackEvent('Tarot_CardDayDescription_Icon_Calendar', 'Click');

    const action = navigateFromCalendar ? navigate : replace;
    action(ROUTES.TAROT_CALENDAR);
  };

  const handleAstrologersPromoPress = () => {
    setCancelScreenAnimation(true);

    return {
      taroCard: card?.text?.name,
      message: `${card?.text?.overview}`,
    };
  };

  useEffect(() => {
    Analytics.trackEvent('Tarot_CardDayDescription', 'Open', { date: isToday ? 'today' : 'previous' });

    /* Astro Calendar */
    dispatch(addActiveTodo({ type: ASTRO_CALENDAR_TODO_TYPE.NAVIGATE, contentId: ASTRO_CALENDAR_NAVIGATE_TODO_ID.TAROT_READINGS }));
    dispatch(addActiveTodo({ type: ASTRO_CALENDAR_TODO_TYPE.NAVIGATE, contentId: ASTRO_CALENDAR_NAVIGATE_TODO_ID.TAROT_CARD_OF_THE_DAY }));
  }, []);

  const Container = cancelScreenAnimation ? View : Animated.View;

  return (
    <View style={styles.root}>
      <Background />

      <View style={styles.header}>
        <Header title={t('TAROT.CARD_OF_THE_DAY_DESCRIPTION.TITLE')} backIcon withBackground={false} />

        <Container entering={FadeIn.delay(50)} style={styles.icon}>
          <TouchableOpacity onPress={handleCalendarPress}>
            <Icon name={'calendar'} size={sw(24)} color={COLORS.AQUA} />
          </TouchableOpacity>
        </Container>
      </View>

      <ScrollView contentContainerStyle={styles.content} showsVerticalScrollIndicator={false} scrollEventThrottle={200}>
        <Container entering={FadeIn.delay(100)} style={styles.cardText}>
          <Text style={styles.date}>{dayjs(date).format('DD MMM YYYY')}</Text>
          {isToday && <Text style={styles.dateDesc}>{t('TAROT.CARD_OF_THE_DAY_DESCRIPTION.NEXT_CARD')}</Text>}
        </Container>

        <CachedImage style={styles.card} source={{ uri: card?.tarot_image?.image_path }} />

        <Container entering={FadeIn.delay(200)}>
          <Text font={'Philosopher'} style={styles.title}>
            {card?.text?.name}
          </Text>
        </Container>

        <Container entering={FadeIn.delay(300)} style={styles.tags}>
          {card?.text?.tags?.split(', ')?.map(tag => (
            <View style={styles.wrTag} key={tag}>
              <Text style={styles.tag}>{tag}</Text>
            </View>
          ))}
        </Container>

        <Container entering={FadeIn.delay(400)} style={styles.description}>
          <Image style={styles.descImage} resizeMode={'contain'} source={BG} />

          <Text font={'Philosopher'} style={styles.descTitle}>
            {t('TAROT.CARD_OF_THE_DAY_DESCRIPTION.TIP')}
          </Text>

          <Text style={styles.descText}>{card?.text?.tip_for_the_day}</Text>

          <Text font={'Philosopher'} style={styles.descTitle}>
            {t('TAROT.CARD_OF_THE_DAY_DESCRIPTION.MEANING')}
          </Text>

          <Text style={styles.descText}>{card?.text?.overview}</Text>
        </Container>

        <Container entering={FadeIn.delay(400)} style={styles.promoWr}>
          <AstrologersPromo type={ASTROLOGERS_PROMO_SIZES.SMALL} place={ASTROLOGERS_PROMO_PLACES.TAROT} onPress={handleAstrologersPromoPress} />
        </Container>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: COLORS.DARK_GREEN,
  },
  header: {
    justifyContent: 'center',
  },
  icon: {
    position: 'absolute',
    right: paddingHorizontal(15),
    paddingTop: SIZES.DEFAULT_HEADER_OFFSET,
  },
  content: {
    paddingHorizontal: paddingHorizontal(16),
    alignItems: 'center',
  },
  cardText: {
    position: 'absolute',
    width: '100%',
    marginTop: paddingVertical(25),
    justifyContent: 'flex-end',
    height: sw(40),
  },
  date: {
    fontWeight: '400',
    fontSize: fs(14),
    lineHeight: fs(20),
    color: COLORS.BEIGE,
    textAlign: 'center',
    opacity: 0.8,
    textTransform: 'capitalize',
  },
  dateDesc: {
    fontWeight: '400',
    fontSize: fs(11),
    lineHeight: fs(20),
    color: COLORS.BEIGE,
    textAlign: 'center',
    opacity: 0.4,
    marginTop: paddingVertical(2),
  },
  card: {
    width: sw(150),
    height: sw(275),
    marginTop: paddingVertical(76),
    marginBottom: paddingVertical(20),
  },
  title: {
    fontWeight: '700',
    fontSize: fs(26),
    lineHeight: fs(28),
    color: COLORS.BEIGE,
    textAlign: 'center',
  },
  tags: {
    width: sw(240),
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: paddingHorizontal(10),
    marginBottom: paddingHorizontal(20),
    justifyContent: 'center',
    textTransform: 'capitalize',
  },
  wrTag: {
    backgroundColor: COLORS.BEIGE_WITH_OPACITY_015,
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: paddingHorizontal(10),
    paddingVertical: paddingVertical(6),
    marginTop: paddingVertical(10),
    marginRight: paddingHorizontal(10),
  },
  tag: {
    fontWeight: '400',
    fontSize: fs(12),
    color: COLORS.BEIGE_2,
  },
  description: {
    backgroundColor: COLORS.DARK_TEAL,
    width: '100%',
    paddingTop: paddingVertical(25),
    paddingBottom: paddingVertical(5),
    paddingRight: paddingHorizontal(18),
    paddingLeft: paddingHorizontal(14),
    marginBottom: paddingVertical(20),
    borderRadius: sw(15),
  },
  descImage: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  descTitle: {
    fontWeight: '700',
    fontSize: fs(26),
    color: COLORS.BEIGE_2,
    marginBottom: paddingVertical(10),
    position: 'relative',
  },
  descText: {
    fontWeight: '400',
    fontSize: fs(15),
    lineHeight: fs(23),
    color: COLORS.WHITE,
    opacity: 0.8,
    marginBottom: paddingVertical(20),
  },
  promoWr: {
    width: '100%',
    marginBottom: paddingVertical(30),
  },
});

export default memo(CardOfTheDay);
