import React, { FC } from 'react';
import { Pressable, StyleSheet, Platform } from 'react-native';

import Analytics from 'analytics';
import { getToken } from 'store/notification/actions';
import { useAppDispatch, useAppSelector } from 'store';

const WebNotificationRequestOverlay: FC = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(state => state.notification.isWebNotificationRequestOverlayVisible);

  const handlePress = () => {
    Analytics.track('Push_WebRequestOverlay_Click');
    dispatch(getToken());
  };

  if (Platform.OS !== 'web' || !isVisible) {
    return null;
  }

  const currentPermission = window?.Notification?.permission;
  if (['denied', 'granted'].includes(currentPermission)) {
    return null;
  }

  return <Pressable style={[StyleSheet.absoluteFill, styles.container]} onPress={handlePress} />;
};

const styles = StyleSheet.create({
  container: {
    zIndex: 999,
  },
});

export default WebNotificationRequestOverlay;
